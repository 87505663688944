import React, { FC, ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { connectRefinementList } from 'react-instantsearch-dom';
import { RefinementListProvided } from 'react-instantsearch-core';
import classNames from 'classnames';

import { UmbracoTags } from '@shared/types';

import { IAlgoliaRefinementItemData, IRefinementItemData } from 'utils/algoliaFilters';

import Title from 'common/Title';
import CheckBoxCustom from 'common/CheckBoxCustom';
import CustomAccordion from 'common/CustomAccordion';

import { IPropsCustomRefinementList } from './model';
import './CustomRefinementList.scss';

export const CustomRefinementList: FC<IPropsCustomRefinementList> = ({
  items,
  refine,
  filterItems,
  sectionId,
  title,
  chosenFilterIds,
  handleRemoveSelectionData,
  isOpen,
  isSmallDevice,
  isCheckedAndHidden,
}): ReactElement | null => {
  const [filtersNumber, setFiltersNumber] = useState<number>(0);
  const [selectedAccordionIndex, setSelectedAccordionIndex] = useState<number>(isOpen ? 0 : -1);

  const uncheckSelectedDisabledItems = useCallback(
    (itemsToCheck: RefinementListProvided['items']) => {
      itemsToCheck.forEach((item) => {
        if (item.count === 0 && item.isRefined) {
          handleRemoveSelectionData(sectionId, parseInt(item.label, 10))();
        }
      });
    },
    []
  );

  useEffect(() => {
    uncheckSelectedDisabledItems(items);
  }, [items]);

  const handleTransformItems = useCallback(
    (
      refinementItems: IAlgoliaRefinementItemData[],
      cmsItems: UmbracoTags.IProductTag[]
    ): IRefinementItemData[] => {
      let activeFiltersNumber = 0;
      const data = cmsItems.map((filterItem: UmbracoTags.IProductTag) => {
        const refinementItem = refinementItems.find((item) => item.label === String(filterItem.id));

        if (refinementItem?.isRefined) {
          activeFiltersNumber += 1;
        }

        return {
          ...filterItem,
          count: refinementItem?.count || 0,
          isRefined: refinementItem?.isRefined || false,
          label: filterItem.title,
          value: refinementItem?.value || [String(filterItem.id)],
        };
      });

      setFiltersNumber(activeFiltersNumber);

      return data;
    },
    []
  );

  const handleOnChange = useCallback(
    (item: IRefinementItemData) => () => {
      refine(item.value);
    },
    [refine]
  );

  const onChangeAccordionCallback = useCallback(({ selectedIndex }) => {
    setSelectedAccordionIndex(selectedIndex);
  }, []);

  const itemsToRender = useMemo(
    () => handleTransformItems(items, filterItems),
    [items, filterItems]
  );

  useEffect(() => {
    if (isCheckedAndHidden && itemsToRender.length === 1) {
      refine(itemsToRender[0].value)
    }
  }, []);

  return (
    <div data-test={`CustomRefinementList-${title}`}
         className={classNames('custom-refinement-list', {
           'custom-refinement-list--hidden': isCheckedAndHidden && itemsToRender.length === 1,
         })}>
      <CustomAccordion
        onChange={onChangeAccordionCallback}
        changeOnClick={false}
        isSmallDevice={Boolean(isSmallDevice)}
        items={[
          {
            key: title,
            header: (
              <span className="custom-refinement-list__top">
                <Title
                  dataTestAttr="FiltersSectionTitle"
                  className="custom-refinement-list__title"
                  Tag="h5"
                >
                  {title}
                </Title>
                {filtersNumber ? (
                  <span className="custom-refinement-list__number">{filtersNumber}</span>
                ) : null}
              </span>
            ),
            content: (
              <div className="custom-refinement-list__content">
                {itemsToRender.map((item: IRefinementItemData) => (
                  <CheckBoxCustom
                    key={item.id}
                    isChecked={chosenFilterIds?.includes(String(item.id))}
                    labelText={item.title}
                    id={`${title}-${item.title}-AlgoliaFilters`}
                    name={`${title}-${item.title}-AlgoliaFilters`}
                    isDisabled={item.count === 0}
                    onChange={handleOnChange(item)}
                  />
                ))}
              </div>
            ),
          },
        ]}
        selectedIndex={selectedAccordionIndex}
      />
    </div>
  );
};

const ConnectedCustomRefinementList = connectRefinementList(CustomRefinementList);

export default ConnectedCustomRefinementList;
